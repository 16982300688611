import Chip from 'components/generics/buttons/Chip';
import { FilterTypeKey } from 'consts/enums/property';
import useFeedFilters from 'pages/feed/hooks/useFeedFilters';
import FilterIcon from 'public/assets/icons/filter.svg';
import classes from './index.module.scss';

const EXCLUDE_FILTERS = [FilterTypeKey.CATEGORIES];

interface Props {
  openFiltersPane: () => void;
}

const FilterChip = ({ openFiltersPane }: Props) => {
  const { getFiltersCount } = useFeedFilters();
  const filterCount = getFiltersCount(EXCLUDE_FILTERS);
  const hasFilters = filterCount > 0;

  return (
    <Chip
      className={classes.filterChip}
      leftComponent={
        <div className={classes.filterContainer}>
          <FilterIcon className={classes.filterIcon} />
          {hasFilters && <div className={classes.count}>{filterCount}</div>}
        </div>
      }
      isSelected={hasFilters}
      onClick={openFiltersPane}
    />
  );
};

export default FilterChip;
