import classNames from 'classnames';
import Chevron from 'public/assets/icons/chevron_left.svg';
import React from 'react';
import classes from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  goBack: () => void;
  goNext: () => void;
  reachedStart?: boolean;
  reachedEnd?: boolean;
}

const BackNextButtons = ({
  goBack,
  goNext,
  reachedStart,
  reachedEnd,
  className,
}: Props) => {
  return (
    <div className={classNames(classes.buttonContainer, className)}>
      <div onClick={goBack}>
        <Chevron
          className={classNames(classes.icon, reachedStart && classes.disabled)}
        />
      </div>
      <div onClick={goNext}>
        <Chevron
          className={classNames(
            classes.icon,
            classes.rightIcon,
            reachedEnd && classes.disabled
          )}
        />
      </div>
    </div>
  );
};

export default BackNextButtons;
