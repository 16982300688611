import { AppSettings } from 'hooks/requests/useAppSettings/utils';
import LendPromotionDiscountBanner from 'pages/feed/components/EventBanner/LendPromotionDiscountBanner';
import PromotionDiscountBanner from 'pages/feed/components/EventBanner/PromotionDiscountBanner';
import React from 'react';
import BlackFridayDiscountBanner from './BlackFridayDiscountBanner';

const enum LANDA_EVENTS {
  PROMOTION_DISCOUNT,
  LEND_PROMOTION_DISCOUNT,
  BLACK_FRIDAY_DISCOUNT,
}

const EventBanners: {
  [key in LANDA_EVENTS]: React.FC;
} = {
  [LANDA_EVENTS.PROMOTION_DISCOUNT]: PromotionDiscountBanner,
  [LANDA_EVENTS.LEND_PROMOTION_DISCOUNT]: LendPromotionDiscountBanner,
  [LANDA_EVENTS.BLACK_FRIDAY_DISCOUNT]: BlackFridayDiscountBanner,
};

export const findCurrentEvent = ({
  appSettings,
  eligibleDiscount,
}: {
  appSettings: AppSettings;
  eligibleDiscount?: boolean;
}) => {
  const curDate = new Date();
  const isInsideDates =
    new Date(appSettings?.landaPromotionEndDate) > curDate &&
    new Date(appSettings?.landaPromotionStartDate) < curDate;
  const discountOn = eligibleDiscount || isInsideDates;

  if (appSettings?.landaPromotionBlackFriday && discountOn) {
    return EventBanners[LANDA_EVENTS.BLACK_FRIDAY_DISCOUNT];
  }
  if (appSettings?.isLandaPromotionActive && discountOn) {
    return EventBanners[LANDA_EVENTS.PROMOTION_DISCOUNT];
  }
  if (appSettings?.isLandaPromotionLendActive && isInsideDates) {
    return EventBanners[LANDA_EVENTS.LEND_PROMOTION_DISCOUNT];
  }
};
