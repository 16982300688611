import classNames from 'classnames';
import Typography from 'components/generics/texts/Typography';
import useEligibleDiscount from 'hooks/requests/account/useEligibleDiscount';
import useAppSettings from 'hooks/requests/useAppSettings/useAppSettings';
import { HTMLAttributes, useMemo, useState } from 'react';
import classes from './index.module.scss';

const PromotionDiscountBanner = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const { discountPromotionEndDate } = useEligibleDiscount();
  const { appSettings } = useAppSettings();

  const toDate = useMemo(() => {
    const eligibleEndDate =
      discountPromotionEndDate && new Date(discountPromotionEndDate);
    const globalEndDate = new Date(appSettings.landaPromotionEndDate);
    if (eligibleEndDate && eligibleEndDate > globalEndDate) {
      return eligibleEndDate;
    }
    return globalEndDate;
  }, [discountPromotionEndDate, appSettings]);

  const [timesUp, setTimesUp] = useState(toDate < new Date());

  if (timesUp) return null;
  return (
    <div
      className={classNames(classes.container, className)}
      {...props}
    >
      {/*<div>*/}
      {/*  <Timer*/}
      {/*    onTimesUp={() => setTimesUp(true)}*/}
      {/*    className={classes.timer}*/}
      {/*    toDate={toDate}*/}
      {/*  />*/}
      {/*</div>*/}
      <Typography className={classes.title}>
        Get a{' '}
        <Typography className={classes.lime}>
          {appSettings?.landaPromotionDiscountPercentage * 100}%
        </Typography>{' '}
        gift when you purchase <br />
        <Typography className={classes.lime}>
          {' '}
          more than ${appSettings.landaPromotionDiscountThreshold}
        </Typography>{' '}
        <Typography className={classes.lime}> an IPO property.</Typography>
      </Typography>
      <Typography className={classes.title}></Typography>
    </div>
  );
};

export default PromotionDiscountBanner;
