import classNames from 'classnames';
import BackNextButtons from 'components/generics/buttons/BackNextButtons';
import Typography from 'components/generics/texts/Typography';
import useMounted from 'hooks/logics/useMounted';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';
import Link from 'next/link';
import { encodeMarket } from 'pages/feed/[market]/dataFetching';
import { WheelControls } from 'pages/feed/components/PropertiesContainer/components/MarketCarousel/utils';
import * as React from 'react';
import { HTMLAttributes } from 'react';
import { useMarketStore } from 'stores/Markets/markets';
import classes from './index.module.scss';

const MarketCarousel = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const { markets } = useMarketStore();
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      rubberband: false,
      loop: false,
      slides: { perView: 'auto', spacing: 24 },
    },
    [WheelControls]
  );

  const mounted = useMounted();
  if (!mounted) return null;

  return (
    <div className={classNames(classes.container, className)}>
      <div className={classes.topContainer}>
        <Typography className={classes.title}>Discover new markets</Typography>
        <BackNextButtons
          goBack={() => instanceRef?.current?.prev()}
          goNext={() => instanceRef?.current?.next()}
        />
      </div>
      <div className={classes.carouselContainer}>
        <div
          ref={sliderRef}
          className={classNames('keen-slider', classes.carousel)}
        >
          {markets.map((market, index) => (
            <Link
              href={`/feed/${encodeMarket(market.name)}`}
              key={market.name}
              className={classNames('keen-slider__slide number-slide-' + index)}
              style={{ maxWidth: 300, minWidth: 200 }}
            >
              <Image
                className={classes.image}
                alt={market.name}
                src={market.photos?.[0]?.uri}
                width={200}
                height={200}
              />
              <Typography className={classes.marketTitle}>
                {market.name}
              </Typography>
              <Typography className={classes.marketSubtitle}>
                {market.numProperties} Properties
              </Typography>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MarketCarousel;
