import classNames from 'classnames';
import ChipsSelector from 'components/features/ChipsSelector';
import { ValueType } from 'components/features/ChipsSelector/types';
import { objectToChipSelectorValues } from 'components/features/ChipsSelector/utils';
import { FilterTypeKey } from 'consts/enums/property';
import useFeedFilters, {
  initialFilters,
} from 'pages/feed/hooks/useFeedFilters';
import { HTMLAttributes } from 'react';
import FilterChip from '../FilterChip';
import classes from './index.module.scss';
import { CATEGORIES_FILTERS } from './utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  openFiltersPane: () => void;
}

const CategoriesFilters = ({ openFiltersPane, className, style }: Props) => {
  const { filters, setFilters, resetFilterType } = useFeedFilters();
  const categories = filters.categories;

  const reset = () => resetFilterType(FilterTypeKey.CATEGORIES);
  const onClick = async (value: ValueType) => {
    await setFilters({
      ...initialFilters,
      categories: [value.toString()],
    });
  };

  return (
    <div
      className={classNames(classes.container, className)}
      style={style}
    >
      <ChipsSelector
        withAllChip
        onClick={onClick}
        selected={categories}
        onAllChipClick={reset}
        className={classes.chipsSelector}
        unselectedClassName={classes.unselectedChip}
        values={objectToChipSelectorValues(CATEGORIES_FILTERS)}
      />
      <FilterChip openFiltersPane={openFiltersPane} />
    </div>
  );
};

export default CategoriesFilters;
