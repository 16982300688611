import classNames from 'classnames';
import useFetchMapProperties from 'hooks/requests/properties/useFetchMapProperties';
import CategoriesFilters from 'pages/feed/components/CategoriesFilters';
import FeedMap from 'pages/feed/components/FeedMap';
import FiltersDrawer from 'pages/feed/components/FiltersDrawer';
import { FeedPropertyType } from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsFetcher';
import useFeed, { FeedProvider } from 'pages/feed/context';
import { getStaticPropsFeedPage } from 'pages/feed/dataFetching';
import React, { useEffect } from 'react';
import { useMarketStore } from 'stores/Markets/markets';
import { MarketType } from 'types/trading-types';
import PropertiesContainer from './components/PropertiesContainer';
import classes from './index.module.scss';
import useUserDetails from '../../hooks/requests/user/hooks/useUserDetails';
import { useLandaRouter } from '../../hooks/logics/useLandaRouter';

interface Props {
  properties: FeedPropertyType[];
  markets: MarketType[];
}

const InnerFeedPage = ({ properties }: Props) => {
  const { shadowClassName, openFiltersPane } = useFeed();
  const { mapProperties } = useFetchMapProperties();
  const { userDetails } = useUserDetails();
  const { router } = useLandaRouter();
  // useEffect(() => {
  //   if (userDetails?.isTenant) {
  //     router.replace('/residents/rent-pay');
  //   }
  // }, [userDetails]);

  return (
    <>
      <div className={classNames(classes.filtersContainer, shadowClassName)}>
        <CategoriesFilters
          className={classes.filters}
          openFiltersPane={openFiltersPane}
        />
      </div>
      <div className={classes.bottomContainer}>
        <PropertiesContainer
          properties={properties}
          className={classes.feed}
        />
        <div className={classes.mapContainer}>
          <FeedMap
            fullPropertyView={true}
            className={classes.map}
            mapProperties={mapProperties}
          />
        </div>
      </div>
      <FiltersDrawer />
    </>
  );
};

const FeedPage = ({ properties, markets }: Props) => {
  const { setMarkets } = useMarketStore();

  useEffect(() => {
    setMarkets(markets);
  }, []);

  return (
    <FeedProvider>
      <InnerFeedPage
        properties={properties}
        markets={markets}
      />
    </FeedProvider>
  );
};

export const getStaticProps = async () => {
  return getStaticPropsFeedPage();
};

export default FeedPage;
