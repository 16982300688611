import DrawerContainer from 'components/containers/DrawerContainer';
import ChipsSelector from 'components/features/ChipsSelector';
import { ValueType } from 'components/features/ChipsSelector/types';
import { objectToChipSelectorValues } from 'components/features/ChipsSelector/utils';
import Typography from 'components/generics/texts/Typography';
import cloneDeep from 'lodash/cloneDeep';
import useFeed from 'pages/feed/context';
import useFeedFilters, {
  FilterType,
  FilterTypes,
  initialFilters,
} from 'pages/feed/hooks/useFeedFilters';
import { useEffect, useState } from 'react';
import { useMarketStore } from 'stores/Markets/markets';
import FilterDrawerApplyButton from './components/FilterDrawerApplyButton';
import FilterDrawerHeader from './components/FilterDrawerHeader';
import classes from './index.module.scss';
import { FILTER_PANE_FILTERS, makeDynamicMarketFilter } from './utils';

const FiltersDrawer = () => {
  const { showFilterPane, dismissFiltersPane } = useFeed();
  const { filters } = useFeedFilters();
  const [newFilters, setNewFilters] = useState<FilterTypes>(filters);
  const { markets } = useMarketStore();

  useEffect(() => {
    if (!showFilterPane) return;
    const cloneFilters = cloneDeep(filters);
    cloneFilters.categories = [];
    setNewFilters(cloneFilters);
  }, [filters, showFilterPane]);

  const resetFilters = () => setNewFilters(cloneDeep(initialFilters));
  const onClick = (type: FilterType, value: ValueType) => {
    if (!newFilters[type]) newFilters[type] = [];
    newFilters[type]?.addOrRemove(value.toString());
    setNewFilters({ ...newFilters });
  };

  const FINAL_FILTERS = [
    ...FILTER_PANE_FILTERS,
    makeDynamicMarketFilter(markets),
  ];

  return (
    <DrawerContainer
      show={showFilterPane}
      onDismiss={dismissFiltersPane}
      className={classes.drawer}
    >
      <FilterDrawerHeader
        filters={newFilters}
        onDismiss={dismissFiltersPane}
        resetFilters={resetFilters}
      />
      <div className={classes.chipsContainer}>
        {FINAL_FILTERS.map((item) => {
          const { type, values, display } = item;
          const onChipClick = (value: ValueType) => onClick(type, value);

          return (
            <div key={type}>
              <Typography className={classes.title}>{display}</Typography>
              <ChipsSelector
                onClick={onChipClick}
                className={classes.chips}
                selected={newFilters[type]}
                values={objectToChipSelectorValues(values)}
              />
            </div>
          );
        })}
      </div>
      <FilterDrawerApplyButton
        onDismiss={dismissFiltersPane}
        filters={newFilters}
      />
    </DrawerContainer>
  );
};

export default FiltersDrawer;
