import useEligibleDiscount from 'hooks/requests/account/useEligibleDiscount';
import useAppSettings from 'hooks/requests/useAppSettings/useAppSettings';
import { findCurrentEvent } from 'pages/feed/components/EventBanner/utils';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const EventBanner = (props: Props) => {
  const { eligibleDiscount } = useEligibleDiscount();
  const { appSettings } = useAppSettings();
  const EventComponent = findCurrentEvent({ appSettings, eligibleDiscount });
  if (!EventComponent) return null;
  return <EventComponent {...props} />;
};

export default EventBanner;
