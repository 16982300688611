import classNames from 'classnames';
import ComponentMounter from 'components/containers/ComponentMounter';
import every from 'lodash/every';
import EventBanner from 'pages/feed/components/EventBanner';
import MarketCarousel from 'pages/feed/components/PropertiesContainer/components/MarketCarousel';
import PropertiesGrid from 'pages/feed/components/PropertiesContainer/components/PropertiesGrid';
import useFeedItemsFetcher, {
  FeedPropertyType,
} from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsFetcher';
import useFeed from 'pages/feed/context';
import useFeedFilters from 'pages/feed/hooks/useFeedFilters';
import React, { HTMLAttributes } from 'react';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  properties: FeedPropertyType[];
}

const PropertiesContainer = ({ properties, className }: Props) => {
  const { handleScroll } = useFeed();
  const { filters, initialized: filtersInitialized } = useFeedFilters();
  const noFilters = every(filters, (value) => !value || value.length === 0);

  return (
    <div className={classNames(classes.container, className)}>
      <EventBanner className={classes.eventBanner} />
      <MarketCarousel className={classes.carousel} />
      <ComponentMounter deps={filters}>
        <PropertiesGrid
          preloadedProperties={properties}
          onScroll={handleScroll}
          fetcher={useFeedItemsFetcher}
          usePreloadedPropertiesCondition={noFilters}
          startedWorkingCondition={filtersInitialized}
          fetcherProps={{}}
        />
      </ComponentMounter>
    </div>
  );
};

export default PropertiesContainer;
