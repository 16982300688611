import { Categories } from 'consts/enums/property';
import SchoolOutlineIcon from 'public/assets/icons/school_outline.svg';
import SecurityCheckIcon from 'public/assets/icons/security_check.svg';
import SuburbsIcon from 'public/assets/icons/suburbs.svg';
import UrbanIcon from 'public/assets/icons/urban.svg';

export const CATEGORIES_FILTERS = {
  [Categories.URBAN]: {
    Icon: UrbanIcon,
  },
  [Categories.SAFE_AREA]: {
    display: 'Safe neighborhoods',
    Icon: SecurityCheckIcon,
  },
  [Categories.COUNTRY_SIDE]: {
    display: 'Suburban',
    Icon: SuburbsIcon,
  },
  [Categories.BEST_SCHOOLS]: {
    display: 'A+ schools',
    Icon: SchoolOutlineIcon,
  },
};
