import { QueryResult } from '@apollo/client';
import useAuth from 'context/Auth';
import { ELIGIBLE_DISCOUNT_QUERY } from 'graphql/account/queries/eligibleDiscountQuery';
import { useLandaAuthedApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

type DiscountData = {
  eligibleDiscount?: boolean;
  discountPromotionEndDate?: string;
  loading: boolean;
  request?: () => Promise<QueryResult<QueryResult>> | undefined;
};

const init: DiscountData = {
  discountPromotionEndDate: '',
  eligibleDiscount: false,
  loading: false,
  request: undefined,
};

const useEligibleDiscount = () => {
  const { isLoggedIn } = useAuth();
  const [discountPromotionEndDate, setDiscountPromotionEndDate] = useState<
    string | undefined
  >(undefined);
  const [eligibleDiscount, setEligibleDiscount] = useState<boolean | undefined>(
    undefined
  );
  const [hasRequested, setHasRequested] = useState<boolean>(false);
  const [request, { loading, data }] = useLandaAuthedApiLazyQuery(
    ELIGIBLE_DISCOUNT_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (isLoggedIn && !hasRequested) {
      setHasRequested(true);
      request();
    } else if (!isLoggedIn) {
      setHasRequested(false);
      setDiscountPromotionEndDate(undefined);
      setEligibleDiscount(undefined);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (data) {
      setDiscountPromotionEndDate(data?.account?.discountPromotionEndDate);
      const curDate = new Date();
      setEligibleDiscount(
        curDate < new Date(data?.account?.discountPromotionEndDate)
      );
    }
  }, [data]);

  return {
    discountPromotionEndDate,
    eligibleDiscount,
    loading,
    request,
  };
};

export default singletonHook(init, useEligibleDiscount);
